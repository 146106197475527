<template>
  <div
    :style="{
      backgroundImage: `url(${bg})`,
    }"
    class="w-full h-full bg-pink-100/90 fixed top-0 bg-center bg-no-repeat bg-cover"
  >
    <div
      id="background"
      class="w-full h-full flex justify-center items-center px-4"
    >
      <h1
        class="text-headline-small md:text-headline-big lg:text-display-small text-white opacity-[0.87]"
      >
        {{ $t("Personalising Your Experience...") }}
      </h1>
    </div>
  </div>
</template>

<script setup>
const bg = new URL(`../assets/pngs/personalize-bg.png`, import.meta.url);
</script>

<style lang="scss" scoped>
#background {
  background: radial-gradient(
      100% 100% at 110% -10%,
      rgba(208, 45, 46, 0.5) 20%,
      rgba(0, 0, 0, 0) 200%
    ),
    radial-gradient(
      100% 100% at -30% 160%,
      rgba(208, 45, 46, 0.5) 20%,
      rgba(0, 0, 0, 0) 200%
    );
}
</style>
